import store from "@/store";
// import api from '../api'
import router from '@/router'

const facility = {
    namespaced:true,
    store,
    state(){
        return {
            data:[
                {
                    id:1,
                    description: "facilty 1",
                    dateCreated: "2023-05-30"
                },
                {
                    id:2,
                    description: "facilty 2",
                    dateCreated: "2023-05-30"
                },
                {
                    id:3,
                    description: "facilty 3",
                    dateCreated: "2023-05-30"
                }
            ]
        }
    },
    mutations:{
        create(state,payload){
            state.data.push(payload)
        },
        modify(state,payload){
            const index = state.data.findIndex(data => data.id == payload.id)
            state.data[index] = payload
        },
        delete(state,payload){
            state.data.splice(payload.index, 1)
        }
    },
    actions:{
        async create(context,payload){
            context.commit("create",payload.payload)

            this.dispatch({
                type:"notification/snackbar",
                data:{
                    type:"success",
                    content: "Success",
                    errorIcon:"mdi-check-bold"
                }
            })
        },
        async modify(context,payload){
            context.commit("modify",payload.payload)

            this.dispatch({
                type:"notification/snackbar",
                data:{
                    type:"success",
                    content:"Modified",
                    errorIcon:"mdi-check-bold"
                }
            })
        },
        async delete(context,payload){
            context.commit("delete",payload.payload)

            this.dispatch({
                type:"notification/snackbar",
                data:{
                    type:"success",
                    content: "Facility has been successfully deleted",
                    errorIcon: "mdi-check-bold"
                }
            })
        }
    },
    getters:{
        datas(state){
            return state.data
        }
    }

} 

export default facility