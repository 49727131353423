<template>

  <v-app class="bg-slate-50">
    
    <v-main>
      
      <v-snackbar
        v-model=" snackbar.active"
        :color="snackbar && snackbar.type"
        timeout="1000"
        location="top"
      >
        <div>
          <span>
            <v-icon left class="space-x-6">{{ snackbar && snackbar.errorIcon }}</v-icon>
            {{ snackbar && snackbar.content }}
          </span>
        </div>
      </v-snackbar>
      <headercomp />
      <v-navigation-drawer v-if="isAuth" app left class="absolute" theme="dark" expand-on-hover rail permanent mini-variant >
        <sidebar/>
      </v-navigation-drawer>
      <router-view />
      <v-bottom-navigation grow height="40" class="fixed">
        <v-card-text class="ml-12 text-xs">
          © 2023 - Goldridge | Cresta De Oro
        </v-card-text>
        <v-spacer></v-spacer>
        <v-btn color="indigo">
          <span class="text-white">SJO</span>
          <v-icon color="light-green accent-3">mdi-chevron-up</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import sidebar from "./views/sidebar.vue";
import headercomp from "./views/header.vue";

export default {
  components: {
    sidebar,
    headercomp,
  },
  name: "App",
  computed:{
    ...mapGetters({
      snackbar: "notification/snackbar",
      isAuth: "Auth/isloggedIn"
    }),
  },
};
</script>
