<template>
    
      <v-list>
        <v-list-item
          prepend-avatar="https://randomuser.me/api/portraits/men/78.jpg"
          title="Admin"
          subtitle="it@crestadeoro.com"
        >
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item
          @click="dashboardPage"
          :color="$route.fullPath == '/home' ? 'blue-lighten-2' : ''"
          :active="$route.fullPath == '/home'"
          prepend-icon="mdi-view-dashboard"
          title="Dashboard"
          value="Dashboard"
        ></v-list-item>
        <v-list-item
          @click="inventoryPage"
          :color="$route.fullPath == '/inventory' ? 'blue-lighten-2' : ''"
          :active="$route.fullPath == '/inventory'"
          prepend-icon="mdi-database"
          title="Inventory"
          value="Inventory"
        ></v-list-item>
        <v-list-item
          @click="transmitalPage"
          :color="$route.fullPath == '/transmital' ? 'blue-lighten-2' : ''"
          :active="$route.fullPath == '/transmital'"
          prepend-icon="mdi-cube-send"
          title="Transmital"
          value="Transmital"
        ></v-list-item>
        <v-list-item
          @click="mrfPage"
          :color="$route.fullPath == '/mrf' ? 'blue-lighten-2' : ''"
          :active="$route.fullPath == '/mrf'"
          prepend-icon="mdi-package-variant"
          title="Mrf"
          value="MRF"
        ></v-list-item>
        <v-list-item
          @click="managementPage"
          :color="$route.fullPath == '/management' ? 'blue-lighten-2' : ''"
          :active="$route.fullPath == '/management' ? true : false"
          prepend-icon="mdi-application-cog"
          title="Management"
          value="Management"
        ></v-list-item>
        <v-list-item
          @click="generatBarcode"
          :color="$route.fullPath == '/generatBarcode' ? 'blue-lighten-2' : ''"
          :active="$route.fullPath == '/generatBarcode'"
          prepend-icon="mdi-barcode-scan"
          title="Generate Barcode"
          value="generatBarcode"
        ></v-list-item>
        <v-list-item
          @click="accountPage"
          :color="$route.fullPath == '/account' ? 'blue-lighten-2' : ''"
          :active="$route.fullPath == '/account'"
          prepend-icon="mdi-account-cog-outline"
          title="Account"
          value="Account"
        ></v-list-item>
        <v-spacer></v-spacer>
        <!-- <v-list-item prepend-icon="mdi-power" title="Logout" value="logout"></v-list-item> -->
      </v-list>
    <v-divider></v-divider>


    
     <v-card class="">
        <v-list density="compact" nav >
          <v-list-item
            color="red-lighten-2"
            @click="logout"
            prepend-icon="mdi-power"
            title="Log Out"
            value="Log Out"
          ></v-list-item>
        </v-list>
     </v-card>
     
    
   


</template>

<script>
import router from "@/router";

export default {
  name: "",
  created(){
    this.$store.dispatch({
      type:"userAccounts/data"
    })

    this.$store.dispatch({
      type:"roles/data"
    })

    this.$store.dispatch({
      type:"department/data"
    })

    this.$store.dispatch({
      type:"employees/data"
    })

    this.$store.dispatch({
      type:"category/data"
    })

    this.$store.dispatch({
      type:"inventory/data"
    })

    this.$store.dispatch({
      type:"projects/data"
    })
  },
  data() {
    return {
      path: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.path = this.$route.fullPath;
    }, 500);
  },
  watch: {
    path(value) {
      console.log(value);
    },
  },
  methods: {
    dashboardPage() {
      router.push("/home");
    },
    inventoryPage() {
      router.push("/inventory");
    },
    transmitalPage() {
      router.push("/transmital");
    },
    managementPage() {
      router.push("/management");
    },
    accountPage() {
      router.push("/account");
    },
    mrfPage() {
      router.push("/mrf");
    },
    generatBarcode() {
      router.push('/generateBarcode')
    },
    logout(){
        this.$store.dispatch({
            type:'Auth/logout'
        })
    }
  },
};
</script>
