// import { createStore } from "vuex";

import Vuex from 'vuex'

import department from './modules/department'
import notification from './modules/notification'
import roles from './modules/roles'
import roleAccess from './modules/roleAccess'
import facility from './modules/facility'
import category from './modules/category'
import inventory from './modules/inventory'
import userAccounts from './modules/userAccounts'
import Auth from './modules/auth'
import employees from './modules/employees'
import projects from './modules/projects'
import transmittals from './modules/transmittals'
import isLoading from './modules/isLoading'

export default new Vuex.Store({
  modules: {
    department,
    notification,
    roles,
    roleAccess,
    facility,
    category,
    inventory,
    userAccounts,
    Auth,
    employees,
    projects,
    transmittals,
    isLoading
  }
})