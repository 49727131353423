import store from "@/store";
// import api from '../api'
import router from '@/router'

const notification = {
    namespaced:true,
    store,
    state(){
        return {
            snackbar:{
                active: false,
                type:"",
                content:"",
                errorIcon:""

            }
        }
    },
    mutations:{
        snackbar(state,payload){
            state.snackbar.active = true
            state.snackbar.type = payload.type
            state.snackbar.content = payload.content
            state.snackbar.errorIcon = payload.errorIcon
        }
    },
    actions:{
        snackbar(context,payload){
            return context.commit('snackbar',payload.data)
        }
    },
    getters:{
        snackbar(state){
            return state.snackbar
        }
    }

} 

export default notification