import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import './index.css'
import { loadFonts } from "./plugins/webfontloader";
import DataTable from 'v-data-table'
import VueBarcode from '@chenfengyuan/vue-barcode';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


loadFonts();

createApp(App)
.use(DataTable)

.use(router)
.use(store)
.use(vuetify)
.component('VueDatePicker', VueDatePicker)
.component(VueBarcode.name, VueBarcode)
.mount("#app");