import store from "@/store";
// import api from '../api'
// import router from '@/router'

const isLoading = {
    namespaced:true,
    store,
    state(){
        return {
            isLoading : false
        }
    },
    mutations:{
        activateLoading(state,payload){
            state.isLoading = payload
        },
        deactivateLoading(state,payload){
            state.isLoading = false
        }
    },
    actions:{
        activateLoading(context,payload){
            context.commit('activateLoading', payload.payload)
        },
        deactivateLoading(context,payload){
            context.commit('deactivateLoading', payload.payload)
        }
    },
    getters:{
        isLoading(state){
            return state.isLoading
        }
    }

} 

export default isLoading