<template>
    <v-app-bar
        color="grey-darken-4"
        prominent
      >
            <v-img @click="logo" max-width="200" class="ml-5 mr-5" src="../assets/gcdc_logo.png"></v-img>
            <v-img @click="logo" max-width="100" class="ml-5 mr-5" src="../assets/cdo_logo.png   "></v-img>
            <v-divider vertical=""></v-divider>
        <v-toolbar-title>GRCDO Inventory</v-toolbar-title>
      </v-app-bar>
</template>

<script>
export default {
    methods:{
        logo(){
            alert(1);
        }
    }
};
</script>
