import store from "@/store";
import api from '../api'
import router from '@/router'

const transmittals = {
    namespaced:true,
    store,
    state(){
        return {
          data : [],
          selectedTransmittalId : 0,
        }
    },
    mutations:{
        createTransmittal(state,payload){
            state.data.push(payload)
            state.selectedTransmittalId = payload.id
        },
        resetSelectedTransmittalId(state,payload){
            // console.log(payload)
            state.selectedTransmittalId = payload
        }
    },
    actions:{
        createTransmittal(context,payload){
            api.post(`/transmittal`,payload.payload)
            .then((res) => {
                context.commit("createTransmittal",res.data.data)

                this.dispatch({
                    type : "notification/snackbar",
                    data : {
                        type : "success",
                        content : res.data.message,
                        errorIcon:"mdi-check-bold"
                    }
                }).then(() => {
                    
                })
            }).catch((err) => {
                context.commit("resetSelectedTransmittalId", 0)
                console.log(err)
            })
        },
        createTransmitted(context,payload){
            console.log(payload)
        }
    },
    getters:{
        data(state){
            return state.data
        },
        selectedTransmittalIdData(state){
            return state.selectedTransmittalId
        }
    }

} 

export default transmittals