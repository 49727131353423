import store from "@/store";
import api from '../api'
import router from '@/router'

const userAccounts = {
    namespaced:true,
    store,
    state(){
        return {
            datas:[]
        }
    },
    mutations:{
        data(state,payload){
            // console.log(payload)
            state.datas = payload
        },
        create(state,payload){
            state.datas.push(payload.user)
        },
        update(state, payload) {
            const index = state.datas.findIndex(item => item.id === payload.id);

            if (index !== -1) {
                // Replace the item at the found index with the new updated data
                state.datas.splice(index, 1, payload.response.data);
            } else {
                console.error('Item not found for update');
            }
        },
        destroy(state, payload) {
            const index = state.datas.findIndex(item => item.id === payload.id);

            if (index !== -1) {
                state.datas.splice(index, 1);
            } else {
                console.error('Item not found for deletion');
            }
        }

    },
    actions:{
        data(context,payload){
            api.get(`/userAccounts`)
            .then(res => {
                // console.log(res.data.data)
                context.commit('data', res.data.data)
            });
        },
        create(context,payload){
            api.post(`/userAccounts`,payload.payload)
            .then(res =>{
                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content:res.data.message
                    }
                })
                context.commit("create",res.data);
            })
        },
        destroy(context,payload){
            console.log(payload.payload.id)
            console.log(payload.payload.index)
            api.delete(`/userAccounts/${payload.payload.id}`)
            .then((res) => {
                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content:res.data.message
                    }
                })
                console.log('Response:', res);
                context.commit('destroy', payload.payload);
            })
        },
        update(context,payload){
            api.put(`/userAccounts/${payload.id}`,payload.payload)
            .then(res =>{
                console.log(res)
                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content:res.data.message
                    }
                })
                context.commit("update",{
                    response : res.data,
                    id : payload.id,
                    index : payload.index
                });
            })
        }
    }, 
    getters:{
        datas(state){
            return state.datas
        }
    }

} 

export default userAccounts