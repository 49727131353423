import store from "@/store";
import api from '../api'
// import router from '@/router'

const department = {
    namespaced:true,
    store,
    state(){
        return {
            data:[]
        }
    },
    mutations:{
        create(state,payload){
            state.data.push(payload.data)
        },
        modify(state,payload){
            const index = state.data.findIndex(data => data.id == payload.response.id)
            state.data[index] = payload.response
        },
        delete(state,payload){
            state.data.splice(payload.index, 1)
        },
        data(state,payload){
            state.data = payload
        }
    },
    actions:{
        data(context,payload){
            api.get(`/departments`)
            .then((res) =>{
                context.commit("data",res.data.data)
            })
        },
        async create(context,payload){
            api.post(`/departments`,payload.payload)
            .then((res) => {
                console.log(res)
                context.commit('create',res.data)
                
                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content:"Success",
                        errorIcon: "mdi-check-bold"
                    }
                })
            })
        },
        async modify(context,payload){
            api.put(`/departments/${payload.selected.id}`,payload.payload)
            .then((res) => {
                context.commit('modify',{
                    response : res.data.data,
                    index : payload.selected.index
                })

                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content: res.data.message,
                        errorIcon: "mdi-check-bold"
                    }
                })
            })
        },
        async delete(context,payload){
            api.delete(`/departments/${payload.payload.id}`)
            .then((res) => {
                context.commit('delete',
                    {
                        response : res.data,
                        index : payload.index
                    }
                )

                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content: res.data.message,
                        errorIcon: "mdi-check-bold"
                    }
                })
            })
            console.log(payload)
        }
    },
    getters:{
        datas(state){
            return state.data
        }
    }

} 

export default department