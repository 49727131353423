import store from "@/store";
import api from '../api'
import router from '@/router'

const roles = {
    namespaced:true,
    store,
    state(){
        return {
            datas:[
                
            ]
        }
    },
    mutations:{
        create(state,payload){
            state.datas.push(payload)
        },
        modify(state,payload){
            const index = state.datas.findIndex(data => data.id === payload.id)
            state.datas[index] = payload
        },
        roles(state,payload){
            state.data.splice(payload.payload.index, 1)
        },
        delete(state,payload){
            state.datas.splice(payload.index, 1)
        },
        datas(state,payload){
            // console.log(payload)
            state.datas = payload
        }
    },
    actions:{
        data(context,payload){
            api.get(`/roles`)
            .then(res => {
                context.commit('datas',res.data.data)
            })
        },
        create(context,payload){
            console.log(payload)
            api.post(`/roles`,payload.payload)
            .then((res) => {
                console.log(res)
            })

            // context.commit('create',payload.payload)

            // this.dispatch({
            //     type:"notification/snackbar",
            //     data:{
            //         type:"success",
            //         content:"Success",
            //         errorIcon:"mdi-check-bold"
            //     }
            // })
        },
        modify(context,payload){
            context.commit('modify',payload.payload)
        
            this.dispatch({
                type:"notification/snackbar",
                data:{
                    type:"success",
                    content: "Modified",
                    errorIcon:"mdi-check-bold"
                }
            })
        },
        delete(context,payload){
            context.commit("delete", payload.payload)

            this.dispatch({
                type:"notification/snackbar",
                data:{
                    type:"success",
                    content: "Role has been successfully deleted",
                    errorIcon: "mdi-check-bold"
                }
            })
        }
    },
    getters:{
        datas(state){
            return state.datas
        }
    }

} 

export default roles