import store from "@/store";
import api from '../api'
import router from '@/router'

const employees = {
    namespaced:true,
    store,
    state(){
        return {
            data : [],
            accountabilitiesData : [],
            accountabilitiesHeaders: [{
                title: "Barcode",
                align: "start",
                key: "tagNo"
              },
              {
                title: "Serial No.",
                align: "start",
                key: "serialNo"
              },
              {
                title: "Catergory",
                align: "start",
                key: "categoryId"
              },
              {
                title: "Description",
                align: "start",
                key: "description"
              },
              {
                title: "End User",
                align: "start",
                key: "endUserId"
              },
              {
                title: "Project",
                align: "start",
                key: "projectIdDescription"
              },
              {
                title: "Date Acquired",
                align: "start",
                key: "dateAquired"
              },
            ],
        }
    },
    mutations:{
        data(state,payload){
            state.data = payload
        },
        create(state,payload){
            state.data.push(payload)
        },
        update(state,payload){
            state.data[payload.index] = payload.response
        },
        delete(state,payload){
            state.data.splice(payload, 1)
        },
        showAccountabilities(state,payload){
            state.accountabilitiesData = payload
        },
        clearData(state){
            state.accountabilitiesData = []
        }
    },
    actions:{
        data (context,payload){
            api.get(`/employees`)
            .then((res) => {
                context.commit('data',res.data.data)
            })
        },
        create(context,payload){
            api.post(`/employees`,payload.payload)
            .then((res) => {
                context.commit('create',res.data.data)

                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content: res.data.message,
                        errorIcon: "mdi-check-bold"
                    }
                })
            })
        },
        update(context,payload){
            console.log(payload)
            api.put(`/employees/${payload.id}`,payload.payload)
            .then((res) => {
                console.log(res)
                context.commit("update",{
                    response : res.data.data,
                    id : payload.id,
                    index : payload.index
                })

                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type : "success",
                        content : res.data.message,
                        errorIcon: "mdi-check-bold"
                    }
                })
            })
        },
        delete(context,payload){
            console.log(payload)
            api.delete(`/employees/${payload.id}`)
            .then((res) => {
                context.commit("delete",payload.index)
                this.dispatch({
                    type: "notification/snackbar",
                    data:{
                        type : "success",
                        content : res.data.message,
                        errorIcon : "mdi-check-bold"
                    }
                })
                
            })
        },
        showAccountabilities(context,payload){
            console.log(payload)
            api.get(`/employeeAccountabilities/${payload.payload}`)
            .then((res) => {
                console.log(res)
                context.commit("showAccountabilities", res.data)
            })
        },
        clearData(context){
            context.commit('clearData')
        }
    },
    getters:{
        datas(state){
            return state.data
        },
        accountabilitiesData(state){
            return state.accountabilitiesData
        },
        accountabilitiesHeaders(state){
            return state.accountabilitiesHeaders
        }
    }

} 

export default employees