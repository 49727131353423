import { createRouter, createWebHistory } from "vue-router";
const routes = [
  //login page starts here
  {
    path: "/",
    name: "login",
    component: () =>
    import("../views/login/login.vue"),
  },
  // login page ends here
  //Dashboard start here
    {
      path: "/home",
      name: "dashboard",
      component: () =>
      import("../views/home.vue"),
      meta: { requiresAuth: true }
    },
  //Dashboard End here

  // Management page start-here
    {
      path: "/management",
      name: "management",
      component: () =>
      import("../views/management/management.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/management/department",
      name: "department",
      component: () =>
      import("../views/management/department.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/management/useraccounts",
      name: "useraccounts",
      component: () =>
      import("../views/management/userAccounts.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/management/roles",
      name: "roles",
      component: () =>
      import("../views/management/roles.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/management/facilities",
      name: "facilities",
      component: () =>
      import("../views/management/facilities.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/management/projects",
      name: "projects",
      component: () =>
      import("../views/management/projects.vue"),
      meta: { requiresAuth: true }
    },
  //Management page end-here

  //Employees page Start Here
    {
      path:"/management/employees",
      name:"employees",
      component:()=>
      import('../views/management/employees.vue'),
      meta: { requiresAuth: true }
    },
  //Employees page Ends Here
    
  //Inventory page start here
    {
      path: "/inventory",
      name: "inventory",
      component: () =>
        import("../views/inventory/inventory.vue"),
        meta: { requiresAuth: true }
    },
    {
      path: "/category",
      name: "category",
      component: () =>
        import("../views/inventory/category.vue"),
        meta: { requiresAuth: true }
    },
  //Inventory end here
  
  //MRF Page Start here
    {
      path:"/mrf",
      name:"mrf",
      component:()=>
      import('../views/mrf/mrf.vue'),
      meta: { requiresAuth: true }
    },
  //MRF Page End here

  //Account Page start here
    {
      path:"/account",
      name:"account",
      component:()=>
      import('../views/account/account.vue'),
      meta: { requiresAuth: true }
    },
  //Account page Ends here

  //Transmital Page Start Here
    {
      path:"/transmital",
      name:"transmital",
      component:()=>
      import('../views/transmital/transmital.vue'),
      meta: { requiresAuth: true }
    },
  //Transmital Page Ends Here

  //generateQR Page Start Here
    {
      path:"/generateBarcode",
      name:"generateBarcode",
      component:()=>
      import('../views/generateBarcode.vue'),
      meta: { requiresAuth: true }
    },
  //generateQR Page Ends Here

  //ProjectInventories Page Start Here
  {
    path:"/management/projects/inventories/:id",
    name:"projectInventories",
    component:()=>
    import('../views/management/projectInventories.vue'),
    meta: { requiresAuth: true }
  },
//ProjectInventories Page Ends Here
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    // Check if the user is authenticated
    if (sessionStorage.getItem('isAuthenticated') !== 'true') {
      // If not authenticated, redirect to the login page
      next('/');
    } else {
      // If authenticated, continue with the navigation
      next();
    }
  } else if (to.path === '/' && sessionStorage.getItem('isAuthenticated') === 'true') {
    // If the user is authenticated and tries to access the login page,
    // redirect them to the home page or any other route
    next('/home');
  } else {
    // If the route does not require authentication, continue with the navigation
    next();
  }
});

export default router;
