import store from "@/store";
import api from '../api'
import router from '@/router'

const category = {
    namespaced:true,
    store,
    state(){
        return {
            data:[],
            filteredData:[],
        }
    },
    mutations:{
        create(state,payload){
            state.data.push(payload)
        },
        modify(state,payload){
            state.data[payload.index] = payload.response
        },
        delete(state,payload){
            state.data.splice(payload, 1)
        },
        search(state,payload){
            if (!payload) return state.filteredData = state.data
            return state.filteredData = state.data.filter(item => item.description.toLowerCase().includes(payload.toLowerCase()))
        },
        data(state,payload){
            state.data = payload
        }
    },
    actions:{
        data(context,payload){
            api.get(`/categories`)
            .then((res) => {
                context.commit('data',res.data.data)
            })
        },
        create(context,payload){
            console.log(payload)
            
            api.post(`/categories`,payload.payload)
            .then((res) => {
                context.commit('create',res.data.data)

                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content: res.data.message,
                        errorIcon: "mdi-check-bold"
                    }
                })
            })
        },
        modify(context,payload){
            api.put(`/categories/${payload.id}`,payload.payload)
            .then((res) => {
                console.log(res)
                context.commit('modify',{
                    response : res.data.data,
                    index : payload.selectedIndex
                })

                this.dispatch({
                    type: "notification/snackbar",
                    data:{
                        type:"success",
                        content: res.data.message,
                        errorIcon: "mdi-check-bold"
                    }
                })
            })
        },
        delete(context,payload){
            console.log(payload)

            api.delete(`/categories/${payload.id}`)
            .then((res) => {
                context.commit("delete",payload.index)

                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content: res.data.message,
                        errorIcon: "mdi-check-bold"
                    }
                })
            })
        },
        search(context,payload){
            context.commit("search", payload.value)
        }
    },
    getters:{
        datas(state){
            return state.data
        },
        filteredData(state){
            return state.filteredData
        }
    }

} 

export default category