import store from '@/store'
import router from '@/router'
import api from '../api'

const auth = {
    namespaced:true,
    store,
    state(){
        return{
            // Use localStorage to get the initial value of isloggedIn
            isloggedIn: sessionStorage.getItem('isAuthenticated') === 'true',
        }
    },
    mutations:{
        login(state,payload){
            
            sessionStorage.setItem('authToken', payload.token);
            sessionStorage.setItem('user', payload.user.name);
            sessionStorage.setItem('isAuthenticated', 'true');
            sessionStorage.setItem('projectId', payload.user.projectId);
            sessionStorage.setItem('roleId', payload.user.roleId);
            // Update the state and store it in localStorage
            state.isloggedIn = true;
            sessionStorage.setItem('isAuthenticated', 'true');

            // console.log(payload);
            router.push('/home');
        },
        logout(state,payload){
            sessionStorage.removeItem('authToken');
            sessionStorage.removeItem('isAuthenticated');
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('projectId')
            sessionStorage.removeItem('roleId')
            // Update the state and localStorage
            state.isloggedIn = false;
            sessionStorage.removeItem('isAuthenticated');
            router.push('/');
        }
    },
    actions:{
        async login(context,payload){
           
            await api.post(`/login`,payload.payload)
            .then((res) => {
                context.commit('login',res.data)
                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content:`Welcome ${res.data.user.name}`
                    }
                })

                this.dispatch({
                    type:"isLoading/deactivateLoading",
                    payload : false
                })
            }).catch((err) => {
                console.log(err)
                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"error",
                        content:"Unauthorized Login"
                    }
                }).then(() => {
                    this.dispatch({
                        type:"isLoading/deactivateLoading",
                        payload : false
                    })
                })

                
            })
            
        },
        async logout(context,payload){
            await api.post(`/logout`,{
            }).then((res) => {
                context.commit('logout',res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    },
    getters:{
        isloggedIn(state){
            return state.isloggedIn
        }
    }
}

export default auth