import store from "@/store";
import api from '../api'

const projects = {
    namespaced:true,
    store,
    state(){
        return {
            data : [],
            projectsData : []
        }
    },
    mutations:{
        data(state,payload){
            state.data = payload
        },
        store(state,payload){
            state.data.push(payload)
        },
        update(state,payload){
            state.data[payload.index] = payload.response
        },
        delete(state,payload){
            state.data.splice(payload, 1)
        },
        projectInventories(state,payload){
            state.projectData = payload
        },
        clearProjectData(state,payload){
            state.projectData = []
        }
    },
    actions:{
        data(context){
            api.get(`/projects`)
            .then((res) => {
                context.commit('data',res.data.data)
            })
        },
        store(context,payload){
            api.post(`/projects`,payload.payload)
            .then((res) => {
                context.commit('store',res.data.data)
                
                this.dispatch({
                    type:"notification/snackbar",
                    data:{
                        type:"success",
                        content: res.data.message,
                        errorIcon:"mdi-check-bold"
                    }
                })
            })
        },
        update(context,payload){
            api.put(`/projects/${payload.payload.id}`,payload.payload)
            .then((res) => {
                context.commit('update',{
                    response : res.data.data,
                    index : payload.payload.index
                })
                
                this.dispatch({
                    type : "notification/snackbar",
                    data : {
                        type : "success",
                        content : res.data.message,
                        errorIcon:"mdi-check-bold"
                    }
                })
            })
        },
        delete(context,payload){
            console.log(payload)
            api.delete(`/projects/${payload.payload.id}`)
            .then((res) => {
                console.log(res)
                context.commit('delete',payload.index)

                this.dispatch({
                    type : "notification/snackbar",
                    data : {
                        type : "success",
                        content : res.data.message,
                        errorIcon:"mdi-check-bold"
                    }
                })
            })
        },
        projectInventories(context,payload){
            api.get(`/projectsInventory/${payload.payload}`)
            .then((res) => {
                console.log(res.data)
                context.commit('projectInventories',res.data)
            })
        },
        clearProjectData(context){
            context.commit('clearProjectData')
        }

    },
    getters:{
        datas(state){
            return state.data
        },
        projectDatas(state){
            return state.projectData
        }
    }

} 

export default projects