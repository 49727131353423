import store from "@/store";
// import api from '../api'
import router from '@/router'

const roleAccess = {
    namespaced:true,
    store,
    state(){
        return {
            data:[
                {
                    id:1,
                    userId:2,
                    description: "asdfasdfasdf",
                    facilityId:1,
                    roleId:1,
                    readOnly:true,
                    readWrite:true,
                    isActive:true,
                    createdByUserId:2,
                    DateCreated: '2023-05-29',
                },
                {
                    id:2,
                    userId:2,
                    description: "asdfasdfasdf",
                    facilityId:1,
                    roleId:1,
                    readOnly:true,
                    readWrite:true,
                    isActive:true,
                    createdByUserId:2,
                    DateCreated: '2023-05-29',
                },
            ]
        }
    },
    mutations:{
     
    },
    actions:{
        
    },
    getters:{
        data(state){
            return state.date
        }
    }

} 

export default roleAccess